import ApiService from "@/services";
import { AxiosResponse } from "axios";

export default class MeasurementUnitService {
  static getMeasurementUnits(): Promise<AxiosResponse> {
    return ApiService.get("/v2/measurement-units", {
      params: { skip: 0, limit: 999 },
    });
  }
}
